.snakke-main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 3000;
}

.snakke-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  max-height: 100%;
  width: 50%;
  background-color: rgb(0, 0, 0);
  /* transition: all linear 0.15s; */
}
