.pageReport {
  position: relative;
  display: block;
  width: 297mm;
  margin: 6px auto;
  margin-bottom: 5mm;
  background-color: rgba(0, 0, 0, 0.5);
  /*max-width: 100%;*/
}

.pageReport .table-def .rt-tr:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.pageReport .sm {
  font-size: 12px;
}

.pageReport .lb {
  display: block;
  font-size: 10px;
  opacity: 0.7;
  line-height: 10px;
}
