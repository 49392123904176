@media print {
  .noPrint {
    display: none;
    visibility: hidden;
  }
}

/* @page {
  margin: 5cm;
} */


table.printTable {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
  box-sizing: border-box;
}

table.printTable td {
  padding: 2px;
  font-size: 10pt;
  overflow: hidden;
}

table.printTable tfoot td{
  border-top: 1px solid #000;
}

table.printTable thead th,
table.printTable thead td {
  border-bottom: 1px solid #000;
}
