.uf-check-toogle-content {
  display: block;
  position: relative;
  max-height: 100%;
  height: 32px;
  width: 64px;
  min-width: 64px;
}

.uf-check-toogle-content:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 10px;
  background-color: rgba(150, 150, 150, 1);
  border-radius: 8px;
  box-shadow: inset rgba(0, 0, 0, 0.5) 1px 1px 2px;
  -o-transition: all ease 0.3s;
  -ms-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  -webkit-transition: all ease 0.3s;
  transition: all ease 0.3s;

  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.uf-check-toogle-input {
  display: none;
}

.uf-check-toogle-input:checked+label {
  -o-transform: translate(32px, -50%);
  -ms-transform: translate(32px, -50%);
  -moz-transform: translate(32px, -50%);
  -webkit-transform: translate(32px, -50%);
  transform: translate(32px, -50%);
}

.uf-check-toogle-input:not(:checked)+label {
  background-color: rgba(100, 100, 100, 1);
}

.uf-check-toogle-input:disabled+label {
  box-shadow: inset rgba(0, 0, 0, 0.5) 2px 2px 2px;
}

.uf-check-toogle-label {
  background-color: rgba(255, 100, 100, 1);
  display: block;
  max-height: 32px;
  height: 32px;
  max-width: 32px;
  width: 32px;
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 0;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 1) 1px 1px 2px;

  -o-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -webkit-transition: all ease 0.2s;
  transition: all linear 0.2s;

  -o-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
