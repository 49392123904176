.toolsbar,
.toolsbar .toolsbar-buttons {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  border: 0;
  height: 42px;
  min-height: 40px;
}

.toolsbar {
  justify-content: space-between;
  overflow: hidden;
  padding-bottom: 4px;
  margin-bottom: 4px;
}

.toolsbar.line {
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}

.toolsbar .toolsbar-title p,
.toolsbar .toolsbar-title h1,
.toolsbar .toolsbar-title h2,
.toolsbar .toolsbar-title h3,
.toolsbar .toolsbar-title h4 {
  font-size: 16px;
  padding: 0;
  margin: 0;
  line-height: 1.6rem;
  max-width: 100%;
  word-wrap: break-word;
  word-break: break-all;
}

.toolsbar+hr {
  height: 1px;
}


.toolsbar .toolsbar-buttons {
  justify-content: flex-end;
  height: 40px;
  min-height: 40px;
}

.toolsbar .toolsbar-buttons .toolsbar-item {
  display: block;
  position: relative;
  border: 0;
  width: 40px;
  height: 40px;
  margin-right: 2px;
}

.toolsbar .toolsbar-buttons .toolsbar-item:first-child {
  margin-left: 2px;
}

.toolsbar .toolsbar-buttons .toolsbar-item:last-child {
  margin-right: 0;
}

.toolsbar .toolsbar-buttons .toolsbar-btn {
  display: block;
  height: 40px;
  width: 40px;
  max-width: 100%;
  max-height: 100%;
  border: 0;
  border-radius: 0;
  overflow: hidden;
  background-color: transparent;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.toolsbar .suplabel,
.toolsbar .table-div-suplabel {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  font-size: 10px;
  line-height: 10px;
  opacity: 0.7;
}

.toolsbar .toolsbar-select-item {
  display: block;
  position: relative;
  padding: 0;
  border: 0;
  /* height: 40px;*/
  margin-right: 2px;
  background-color: rgba(255, 255, 255, 0.);
}

.toolsbar .toolsbar-select-item select{
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
  overflow: hidden;
  text-indent: 0.01px;
  text-overflow: '';

  background-position: center right;
  background-image: url(./ToolSelectItem/seta.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  margin: 0;
  cursor: pointer;
  border: 1px solid #FFF;

  background-color: #FFF;
  border-radius: 0;
  font-size: 16px;
  display: inline-block;
  /* padding: 4px 4px; */
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 44px;
}



.toolsbar .toolsbar-buttons *:hover {
  /* background-color: rosybrown;*/
}

.toolsbar .toolsbar-buttons .toolsbar-btn:disabled,
.toolsbar .toolsbar-buttons .toolsbar-btn.disabled,
.toolsbar .toolsbar-buttons .toolsbar-btn.disabled:hover {
  background-color: rgba(255, 255, 255, 0.3) !important;
  filter: grayscale(100%);
}
