html{
  box-sizing: border-box;
  height: 100%;
  font-size: 62.5%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  height: 100%;
  min-height: 100%;
  outline: none;
  max-width: 100%;
}

body{
  box-sizing: border-box;
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
  display: block;
  outline: none;
  height: 100%;
  max-width: 100%;
  width: 100%;
  overflow-x: hidden;
}

#root {
  position: relative;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  display: block;
  outline: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-height: 100%;

}

#root {
  height: 100%;
  min-height: 100vh;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  display: block;
  padding: 0;
  position: relative;
}

a {
  color: inherit;
}

p {
  font-size: 1.618rem;
  padding: 0;
  margin: 0 auto;
  padding-bottom: 0.618rem;
  display: block;
}

.logo {
  position: relative;
  display: block;
  margin: 8px auto;
  /* max-width: 100%;*/
  max-height: 64px;
  padding: 0;
}


/* ALIGN */
.c {
  text-align: center;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
}
.r { text-align: right; }
.l { text-align: left; }
img { max-width: 100%; }

hr {
  background-color: rgba(255, 255, 255, 0.3);
  height: 1px;
  border: 0;
  padding: 0;
  margin: 4px 0 8px 0;
}

img.c {
  display: block;
  margin: 0 auto;
}
.pad2{ padding: 2px;}
.pad4{ padding: 4px;}
.pad6{ padding: 6px;}

/* DISPLAY */
.hide {
  display: none;
}

/* FONTS */
.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.small {
  font-size: 1rem;
}

.f12 {
  font-size: 1.2rem;
}

.f14 {
  font-size: 1.4rem;
}

.f16 {
  font-size: 1.6rem;
}

.txtShd-white {
  text-shadow: rgba(255, 255, 255, 0.1) 0 0 1px;
}

.txtShd-dark {
  text-shadow: rgba(0, 0, 0, 0.1) 0 0 1px;
}

/**/


.contentPage {
  width: 800px;
  max-width: 100%;
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
}

/* FLEX */
.flex {
  position: relative;
  display: flex;
  flex-flow: row;
}

.nowrap {
  position: relative;
  flex-wrap: nowrap !important;
}

.wrap {
  position: relative;
  flex-wrap: wrap !important;
}

.flex.center {
  align-items: center;
  justify-content: center;
  align-content: center;
}

.flex.between {
  align-content: flex-start;
  justify-content: space-between;
  align-items: flex-start;
}

/* BTN */
.btn44 {
  display: inline-block;
  width: 44px;
  height: 44px;
  background-color: aqua;
  padding: 0;
  margin: 0 auto;
}

.btn32 {
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0 auto;
  overflow: hidden;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.myblockpicker {
  box-shadow: rgba(255, 255, 255, 0.7) 0px 0px 3px !important;
}
.myblockpicker span div {
  box-shadow: rgba(0,0,0,0.5) 1px 1px 2px;
}

/* CORRECAO PARA DESKTOP */
@media screen and (max-width: 720px) {
  /* DISPLAY */
  .hide720 {
    display: none;
  }
}


/* CORRECAO PARA DESKTOP */
@media screen and (min-width: 721px) {
  .header {
    min-height: 64px;
  }
  /* DISPLAY */
  .hide720 {
    display: block;
  }
}

/* @import url('./tabledef.css'); */
@import url('./ctrl-tabs.css');
@import url('./private-reports.css');
