.ReactTable .rt-tbody {
  overflow-x: hidden !important;
}

.table-def {
  width: 100%;
  max-width: 100%;
  border: 0;
}

.table-def .rt-tr {
  position: relative;
  border: 0;
  border-bottom: rgba(255, 255, 255, 0.3) 1px solid;
  border-left: rgba(255, 255, 255, 0) 2px solid;
}

.table-def .rt-tr.selected {
  border-left: rgba(255, 255, 255, 1) 2px solid;
}

.table-def .rt-td {
  border: 0;
  position: relative;
  padding: 4px !important;
  margin: 0;
  font-size: 14px;
}

.table-def .c {
  text-align: center;
}

.table-def .l {
  text-align: left;
}

.table-def .r {
  text-align: right;
}

.div-ctrl-linha {
  display: block;
  position: relative;
  border: 0;
  margin: 0;
  padding: 0;
  /* background-color: rgba(255, 255, 255, 0.1);*/
  min-width: 100%;
}

.div-ctrl-linha .ctrl-linha-bg {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.div-ctrl-linha label {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('input-unchecked.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.div-ctrl-linha .ctrl-linha {
  display: none;
  visibility: hidden;
}

.div-ctrl-linha .ctrl-linha[type='checkbox']+label {
  background-image: url('input-unchecked.svg');
}

.div-ctrl-linha .ctrl-linha[type='checkbox']:checked+label {
  background-image: url('input-checked.svg');
}

.div-ctrl-linha .ctrl-linha[type='radio']+label {
  background-image: url('radio-unchecked.svg');
}

.div-ctrl-linha .ctrl-linha[type='radio']:checked+label {
  background-image: url('radio-checked.svg');
}

.div-ctrl-linha .ctrl-linha:disabled+label,
.div-ctrl-linha .ctrl-linha[disabled='true']+label {
  opacity: 0.3;
}

/* FILTER */
.filter-table-box {
  margin: 0 auto;
  padding: 4px;
  border: 0;
  font-size: 16px;
}

.filter-table-box.content {
  width: 297mm;
  max-width: 100%;
}

.filter-table-title {
  font-size: 14px;
}

.filter-table-content {
  max-width: 100%;
  width: 100%;
  display: flex;
  margin-bottom: 4px;
}

.filter-table-content .campo {
  max-width: 100%;
  display: block;
}

.filter-table-content .campo.w50 {
  max-width: 100%;
  width: 100%;
  display: block;
}

.filter-table-content .campo.w50:first-child {
  padding-right: 2px;
}

.filter-table-content .campo.w50:last-child {
  padding-left: 2px;
}

.filter-table-content .campo.w100 {
  width: 100%;
}

/* CORRECAO PARA DESKTOP */
@media screen and (min-width: 721px) {
  .filter-table-content .campo.w50 {
    width: 50%;
  }

  .filter-table-content .campo.w100 {
    width: 100%;
  }
}
