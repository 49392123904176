.formDomus,
.formDomus form,
.formDomus fieldset,
.formDomus .campo {
  position: relative;
  display: block;
  max-width: 100% !important;
  border: 0;
}

.formDomus form {
  margin: 0 auto;
  padding: 0;
}

.formDomus form hr {
  background-color: rgba(255, 255, 255, 0.3);
  height: 1px;
  border: 0;
  padding: 0;
  margin: 4px 0 8px 0;
}

.formDomus .formContent,
.formDomus.formContent {
  margin: 0 auto;
  width: 420px;
  border: 0;
}

/* GRUPO */
.formDomus .grupo {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: center;
}

.formDomus .grupo.between {
  justify-content: space-between;
  align-items: center;
  align-content: center;
}


.formDomus .grupo .w50 {
  width: 100%;
  max-width: 100%;
}

.formDomus .grupo .w30 {
  width: 33.333%;
  max-width: 33.333%;
}

.formDomus .grupo .w25 {
  width: 25%;
  max-width: 25%;
}


.formDomus .grupo.fixed {
  flex-flow: row nowrap;

}

.formDomus .grupo.fixed .w50 {
  width: 50% !important;
  max-width: 50% !important;
}

.formDomus .grupo.fixed .w50:first-child {
  padding: 0px 2px 8px 0px;
  border: 0;
}

.formDomus .grupo.fixed .w50:last-child {
  padding: 0px 0px 8px 2px;
  border: 0;
}

.formDomus .grupo.fixed .w30 {
  width: 33.333% !important;
  max-width: 33.333% !important;
}

/* CAMPO */
.formDomus .campo {
  padding: 0px 0px 8px 0px;
  border: 0;
}

.formDomus .campo.lbicon,
.formDomus .campo.search {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
}

.formDomus .search input {
  order: 0;
  display: block;
  flex-grow: 1;
  max-width: 100%;
  border: #FFF 1px solid;
  background-color: #FFF;
  border-radius: 0;
  font-size: 16px;
  padding-left: 6px;
  padding-right: 6px;
}

.formDomus .search button {
  order: 1;
  display: block;
  width: 44px;
  height: 44px;
  max-width: 44px;
  max-height: 44px;
  border-radius: 0;
  font-size: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.formDomus .lbicon input,
.formDomus .lbicon select {
  order: 1;
  /*width: 100%;*/
  display: block;
  flex-grow: 1;
  max-width: 100%;
  border: #FFF 1px solid;
  background-color: #FFF;
  border-radius: 0;
  font-size: 1.6rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.formDomus .lbicon select {
  border: #000 1px solid;
}

.formDomus .lbicon input:read-only,
.formDomus .lbicon input[read-only] {
  background-color: rgba(255, 255, 255, 0.7);
}

.formDomus .campo.disabled,
.formDomus input:disabled,
.formDomus button:disabled,
.formDomus .lbicon input[disabled] {
  filter: grayscale(100%);
}

.formDomus .lbicon label[data-lbicon],
.formDomus .campo button[data-lbicon] {
  order: 0;
  border: rgba(0, 0, 0, 0) 1px solid;
  margin-right: 8px;
  display: block;
  width: 44px !important;
  min-width: 44px;
  height: 44px !important;
  min-height: 44px;
  cursor: pointer;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}


.formDomus .campo button,
.formDomus .campo .button {
  margin: 0 auto;
  display: inline-block;
  height: 44px;
  min-height: 44px;
  max-height: 44px;
  max-width: 100%;
  width: 100%;
  font-size: 14px;
  padding: 0;
  border: rgba(0, 0, 0, 0) 1px solid;
  line-height: 42px;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
}

.formDomus .campo .btnText {
  line-height: 40px;
  background: none;
  border: #FFF 1px solid;
  color: #FFF;
  text-decoration: none;
}

.formDomus .campo a.btnText {
  display: block;
}

.formDomus .campo .btnText:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.formDomus .w50 button,
.formDomus .w50 .button {
  width: 100%;
}

.formDomus .campo .btnTab {
  border: 1px solid rgba(255,255,255,0);
  border-bottom-color: #fff;
}

.formDomus .campo .btnTab.active {
  border-bottom-width: 4px;
}

.formDomus .campo.search button{
  max-width: 44px;
  width: 44px;
}


/* DIV SELECT */
.formDomus .div-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
  overflow: hidden;
  text-indent: 0.01px;
  text-overflow: '';
  background-position: top right;
  background-image: url(./assets/select-seta.svg);
  background-repeat: no-repeat;
  background-size: 44px 88px;
  width: 100%;
  margin: 0;
  padding-left: 0.6rem;
  font-size: 1.6rem;
  font-family: Georgia, "Times New Roman", Times, serif;
  height: 44px;
  cursor: pointer;
  border: 1px solid #FFF;
}

.formDomus .div-select select::-ms-expand {
  display: none;
}

/* For IE */
.formDomus .div-select select:disabled {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}

.formDomus .div-select select:focus {
  border-color: rgba(13, 126, 204, 1);
  background-position: bottom right;
}

.label-button {
  display: inline-block;
  order: 0;
  border: rgba(0, 0, 0, 0) 1px solid;
  margin: 0;
  width: 44px;
  max-width: 44px !important;
  height: 44px;
  max-height: 44px !important;
  cursor: pointer;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.label-button.disabled,
.label-button[disabled],
.label-button:disabled {
  filter: grayscale(100%);
  opacity: 0.9;
  background-color: rgba(255, 255, 255, 0.2);
  cursor: default;
}

/* ICONS LABELS */
.label-button[data-lbicon="undo"],
.formDomus [data-lbicon="undo"],
.formDomus [data-lbicon="undo"] {
  background-image: url(./assets/lb-undo.svg);
}

.label-button[data-lbicon="name"],
.formDomus [data-lbicon="nome"],
.formDomus [data-lbicon="name"] {
  background-image: url(./assets/lb-nome.svg);
}

.label-button[data-lbicon="exclude"],
.formDomus [data-lbicon="exclude"] {
  background-image: url(./assets/lb-exclude.svg);
}

.label-button[data-lbicon="email"],
.formDomus [data-lbicon="email"] {
  background-image: url(./assets/lb-email.svg);
}

.label-button[data-lbicon="add"],
.formDomus [data-lbicon="add"] {
  background-image: url(./assets/lb-add.svg);
}

.label-button[data-lbicon="follow"],
.formDomus [data-lbicon="follow"] {
  background-image: url(./assets/lb-follow.svg);
}

.label-button[data-lbicon="label"],
.formDomus [data-lbicon="rotu"],
.formDomus [data-lbicon="label"] {
  background-image: url(./assets/lb-rotu.svg);
}

.label-button[data-lbicon="senha"],
.formDomus [data-lbicon="senha"],
.formDomus [data-lbicon="password"] {
  background-image: url(./assets/lb-pwd.svg);
}

.label-button[data-lbicon="tel"],
.formDomus [data-lbicon="tel"] {
  background-image: url(./assets/lb-tel.svg);
}

.label-button[data-lbicon="cel"],
.formDomus [data-lbicon="cel"] {
  background-image: url(./assets/lb-cel.svg);
}

.label-button[data-lbicon="macaddr"],
.formDomus [data-lbicon="macaddr"] {
  background-image: url(./assets/lb-mac.svg);
}

.label-button[data-lbicon="barcode"],
.formDomus [data-lbicon="barcode"] {
  background-image: url(./assets/lb-bar.svg);
}

.label-button[data-lbicon="timeout"],
.formDomus [data-lbicon="timeout"] {
  background-image: url(./assets/lb-timeout.svg);
}

.label-button[data-lbicon="version"],
.formDomus [data-lbicon="version"] {
  background-image: url(./assets/lb-version.svg);
}

.label-button[data-lbicon="resend"],
.formDomus [data-lbicon="resend"] {
  background-image: url(./assets/lb-resend.svg);
}

.label-button[data-lbicon="link"],
.formDomus [data-lbicon="link"] {
  background-image: url(./assets/lb-link.svg);
}

.formDomus .line-toogle {
  border: 0;
  min-height: 44px;
}

.formDomus .line-toogle-text {
  flex-grow: 1;
  font-size: 1.4rem;
  word-break: break-all;
  position: relative;
}

.formDomus .line-toogle-text .line-toogle-label {
  display: block;
  cursor: pointer;
  word-break: break-all;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


/* CORRECAO PARA DESKTOP */
@media screen and (min-width: 721px) {

  .formDomus .grupo .w50 {
    width: 50%;
    max-width: 50%;
  }

  .formDomus .grupo .w30 {
    width: 33.333%;
    max-width: 33.333%;
  }

  .formDomus .grupo .w50:first-child,
  .formDomus .grupo .w30:first-child {
    padding-right: 4px;
  }


  .formDomus .grupo .w50:last-child,
  .formDomus .grupo .w30:last-child {
    padding-left: 4px;
  }
}
