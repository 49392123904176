.new-menu-lateral-land {
  z-index: 2998;
  position: absolute;
  top:0;
  height: 100%;
  width: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.2s ease-in-out;
}

.new-menu-lateral-land.open {
  position: absolute;
  top:0;
  width: 100%;
  height: 100%;
}

.new-menu-lateral{
  z-index: 2999;
}
.new-menu-lateral.open {
  -o-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}
.new-menu-lateral.left.close{
  -o-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.new-menu-lateral.right.close{
  -o-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}



.new-menu-lateral .label-close {
  width: 44px;
  height: 44px;
  padding: 2px;
  cursor: pointer;
}

.new-menu-lateral .title-container,
.title-container-name {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  align-content: center;
  align-items: center;
  flex-flow: row nowrap;
  overflow: hidden;
}

.new-menu-lateral .title-container{
  justify-content: space-between;
  background-color: rgba(0,0,0,0.3);
}

.new-menu-lateral .title-container-name{
  justify-content: flex-start;
}


.new-menu-lateral .new-menu-lateral-container{
  display: block;
  position: relative;
  padding: 0 4px;
  margin: 0 auto;
}


.new-menu-lateral .new-menu-links,
.new-menu-lateral .new-menu-links ul {
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 0;
}

.new-menu-lateral .new-menu-links ul {
  padding: 0;
  margin: 0;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: rgba(255, 255, 255, 1) 1px solid;
}

.new-menu-lateral .new-menu-links ul li {
  list-style: none;
  padding: 0;
  border: 0;
  margin: 2px 0 2px 0;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
}

.new-menu-lateral .new-menu-links ul li a {
  text-decoration: none;
  display: block;
  padding: 4px 8px;
  font-size: 16px;
  transition: all ease 0.2s;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
}

.new-menu-links ul li a:hover,
.new-menu-links ul li a.active {
  background-color: rgba(0, 0, 0, 0.5);
}

.new-menu-links h3{
  padding: 4px 6px;
}

.new-menu-links-buttons {
  display: block;
  max-width: 100%;
  border: 0;
}


.new-menu-links-buttons button{
  display: block;
  width: 100%;
  padding: 4px;
  font-size: 16px;
  margin: 0 auto;
  border: 1px solid rgba(0, 0, 0, 0);
}
