.header {
  box-sizing: border-box;
  position: relative;
  min-height: 44px;
  width: 100%;
  top: 0;
  left: 0;
  max-width: 100%;
  border-bottom: #fff 2px solid;
  padding: 8px 4px;
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  /*z-index: 2999;*/
  transition: all ease 0.2s;
  font-size: 40px;
}

.header.center {
  justify-content: center;
}

.header-logo {
  display: block;
  max-height: 100%;
  height: 40px;
}

.header .header-item-menu {
  box-sizing: border-box;
  max-height: 44px;
  font-size: 40px;
  padding: 0 4px 0 0;
}

.header-tools label,
.header-item-menu label {
  box-sizing: border-box;
  display: block;
  position: relative;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  /*background: rgba(0, 0, 0, 0.5);*/
}

.header-tools {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.header-tools div{
  box-sizing: border-box;
  max-height: 44px;
  font-size: 40px;
  padding: 0 4px 0 0;
  border: 0;
  margin-left: 4px;
}
