.button-pagination {
  margin: 0 auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 14px;
  cursor: pointer;
  outline-width: 0;
}

.button-pagination:disabled,
.button-pagination.disabled {
  opacity: 0.4;
  background-color: transparent;
  cursor: default;
}
